@import 'Styles/Medborgarskolan/includes';

/**
*
* VideoMessage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'VideoMessage.Base.module';

.VideoMessage {
    $root: &;

    &::before {
        background: black;
    }

    &__Content {
        color: white;

        a {
            color: white !important;
            text-decoration: underline !important;

            &:hover {
                color: white !important;
                text-decoration: none !important;
            }

            @include href-external {
                &::after {
                    background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/external--white.svg') !important;
                }
            }
        }
    }

    &__Text {
        font-weight: $bold;
    }

    &__Button {
        @include u-button(primary);

        @include media(sm) {
            @include u-button(primary);
        }
    }
}
