@import 'Styles/Base/includes';

/**
*
* VideoMessage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.VideoMessage {
    $root: &;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        opacity: .8;
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 8px;
        color: $text-color;
        font-size: 1.2rem;
        line-height: 1.4;
        text-align: center;

        a {
            color: $link-color;

            &:hover {
                color: $link-hover-color;
                text-decoration: underline;
            }
        }

        @include media(xs) {
            padding: 20px;
            font-size: 1.4rem;
        }

        @include media(sm) {
            font-size: 1.8rem;
        }
    }

    &__Text {
        max-width: 500px;
        margin: 0 !important;
        font-size: 1.2rem !important;

        @include media(xs) {
            font-size: 1.4rem !important;
        }

        @include media(sm) {
            font-size: 1.8rem !important;
        }
    }

    &__Button {
        margin-top: 8px;

        @include media(sm) {
            margin-top: 24px;
        }
    }

    &__Link {
        margin-top: 8px;

        @include media(sm) {
            margin-top: 24px;
        }
    }
}
