@import 'Styles/Dansskolanentre/includes';

/**
*
* VideoMessage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'VideoMessage.Base.module';

.VideoMessage {
    $root: &;

    &__Content {
        a {
            border: none !important;
        }
    }

    &__Text {
        color: black !important;
        font-weight: $bold;
    }

    &__Button {
        @include u-button($primary-color solid small);

        @include media(sm) {
            @include u-button($primary-color solid);
        }
    }

    &__Link {
        font-weight: $bold;
    }
}
