@import 'Styles/Pahlmans/includes';

/**
*
* VideoMessage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'VideoMessage.Base.module';

.VideoMessage {
    $root: &;

    &__Text {
        font-weight: $bold;
    }

    &__Button {
        @include u-button(primary small);

        @include media(sm) {
            @include u-button(primary);
        }
    }
}
